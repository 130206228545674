import { Form } from '@innedit/formidable';
import { UserData } from '@innedit/innedit';
import React, { FC } from 'react';

import { LoginEL } from '../containers/Login';
import restauration from '../params/restauration.json';
import TemplatePublic from '../templates/public';

const PageRestauration: FC = () => {
  const handleOnSubmit = async (values: any) => {
    const userData = new UserData();
    await userData.restauration(values.email);
  };

  return (
    <TemplatePublic className="p-24 flex flex-col justify-center items-center min-h-main">
      <div className="max-w-md mx-auto">
        <h1 className="text-lg text-center mb-6">
          Récupérer sont mot de passe
        </h1>
        <LoginEL>
          <Form
            className="w-full"
            datas={restauration}
            footerProps={{
              className: 'mt-6 flex justify-end',
            }}
            name="forget"
            onSubmit={handleOnSubmit}
            submitProps={{
              label: 'Changer le mot de passe',
            }}
          />
        </LoginEL>
      </div>
    </TemplatePublic>
  );
};

export default PageRestauration;
